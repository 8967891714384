.App {
    font-family: "Montserrat", sans-serif;
}

*, *::before, *::after {
    box-sizing: border-box;
}

body, header, section, h1, h2, h3, h4, h5, h6, a, p, nav, ul, ol, li, table, tr, th, thead, tbody {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
}

ul, ol {
    list-style: none;
}

a {
    color: #fff;
    text-decoration: none;
}