.assignment {
    margin-top: 50px;
}

.title {
    margin-bottom: 30px;
}

.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.assignment__table {
    width: 100%;
    border: 2px #2158D9 solid;
}

.assignment__thead {
    text-align: left;
    font-size: 20px;
}

.assignment__thead th {
    border: 1px #2158D9 solid;
    padding: 10px;
}

.assignment__tbody td {
    border: 1px #2158D9 solid;
    padding: 10px;
    font-size: 18px;
}

.assignment__tbody td:first-child {
    font-weight: 600;
    text-align: center;
}



