.classlist {
    margin-top: 100px;
}

.container {
    max-width: 1350px;
    margin: 0 auto;
}

.title {
    background-color: #2158D9;
    color: #fff;
    padding: 20px 0px 20px 30px;
    border-radius: 10px 10px 0 0;
}

.classlist__table {
    width: 100%;
    border: 2px #2158D9 solid;
    /* border-radius: 0 0 10px 10px; тут не получилось применить округление */

}

.classlist__thead {
    text-align: left;
    font-size: 20px;
}

.classlist__thead th {
    border: 1px #2158D9 solid;
    padding: 10px;
}

.classlist__tbody td {
    border: 1px #2158D9 solid;
    padding: 10px;
    font-size: 18px;
}

.classlist__tbody td:first-child {
    font-weight: 600;
    text-align: center;
}

.classlist__btn {
    border-radius: 6px;
    padding: 5px;
    color:#fff;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: all 0.15s linear;
}

.classlist__delete {
    background-color: red;
    border: red solid 1px;
}

.classlist__delete:hover {
    background-color: #fff;
    color: red;
}

.classlist__view, .addForm {
    border: #2158D9 solid 1px;
    background-color: #2158D9;
}

.classlist__view:hover, .addForm:hover {
    background-color: #fff;
    color: #2158D9;
}

.classlist__edit, .allStudents {
    background-color: #0D204C;
    border: #0D204C solid 1px;
}

.classlist__edit:hover, .allStudents:hover {
    background-color: #fff;
    color: #0D204C;
}

.addForm, .allStudents {
    margin-top: 30px;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 500;
}

@media (max-width: 1350px) {
    .container {
        max-width: 1100px;
    }
}

@media (max-width: 1120px) {
    .container {
        max-width: 900px;
    }
}

@media (max-width: 920px) {
    .container {
        max-width: 650px;
    }
}

@media (max-width: 670px) {
    .container {
        max-width: 500px;
    }
}

@media (max-width: 570px) {
    .container {
        max-width: 350px;
    }
    
}

