.load-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
}

.loading {
    font-weight: 600;
    font-size: 40px;
}


.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    }

.title {
    background-color: #2158D9;
    color: #fff;
    padding: 20px 0px 20px 30px;
    border-radius: 10px 10px 0 0;
    font-size: 35px;
}

.teacherlist__table {
    width: 100%;
    border: 2px #2158D9 solid;
}

.teacherlist__thead {
    text-align: left;
    font-size: 20px;
}

.teacherlist__thead th {
    border: 1px #2158D9 solid;
    padding: 10px;
}

.teacherlist__tbody td {
    border: 1px #2158D9 solid;
    padding: 10px;
    font-size: 18px;
}

.teacherlist__tbody td:first-child {
    font-weight: 600;
    text-align: center;
}





