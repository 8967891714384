.header {
    background-color: #0D204C;
    width: 100%;
}

.header__inner {
    max-width: 1560px;
    padding: 20px 5px;
    margin: 0 auto;
}

.navbar__brand {
    font-size: 20px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__item {
    margin-right: 25px;
}



