.form {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #0D204C;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container {
    max-width: 1200px;
    width: 100%;
    padding-top: 50px;
    margin: 0 auto;
}

.title {
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.form input[type="text"],
.form input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form div {
    margin-bottom: 20px;
}

.form label {
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: white;
}

.btn__remove, .btn__add {
    padding: 5px 10px;
    background-color: #c9302c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s linear;
}

.btn__remove:hover {
    background-color: #ad2a26;
}

.btn__add {
    background-color: white !important;
    color: #2158D9 !important;
}

.btn__add:hover {
    background-color: #2158D9 !important;
    color: #fff !important;
}

.btn__save {
    padding: 10px 20px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.btn__save:hover {
    background-color: #4cae4c;
}

.teacher__item {
    margin-bottom: 15px !important;
}
